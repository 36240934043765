import logo from './guillotine-logo.svg';
import { Link } from 'react-router-dom';

export default function Header() {
    return (
      <header className="App-header">
        <Link to="/"><img src={logo} className="guillotine-logo" alt="logo" /></Link>
      </header>
    
    );
  }