import { Link } from "react-router-dom";

export default function Footer() {
    return (

        <footer className="site-footer">
            <p>©2023 All rights reserved. Made on planet Earth by Studio Guillotine. <Link to="/privacy">Privacy policy.</Link> </p>
        </footer>

    );
}