import TwitterIcon from "./twitter.svg";
import DribbbleIcon from "./dribbble.svg";
import InstagramIcon from "./instagram.svg";

const sites = [
  {
    id: 0,
    name: 'Twitter',
    url: 'https://twitter.com/guillotinenz',
    iconClass: 'twitter',
    iconImg: TwitterIcon
  }, {
    id: 1,
    name: 'Dribbble',
    url: 'https://dribbble.com/guillotinenz',
    iconClass: 'dribbble',
    iconImg: DribbbleIcon

  }, {
    id: 2,
    name: 'Instagram',
    url: 'https://instagram.com/guillotinenz',
    iconClass: 'instagram',
    iconImg: InstagramIcon

  }
];

export default function Social() {

    const listItems = sites.map(site =>
        <li className={site.iconClass} key={site.id}>
            
            <a 
            href={site.url}
            className="social-icon"
            style={{
                backgroundImage: `url(${site.iconImg})`,
              }}
     
            > 
            {site.name}
            </a>
        
        </li>
      );

  return <ul className="social">{listItems}</ul>;

}