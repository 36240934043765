import HeroImg from "./splash-pg-hero-adj.jpg";

export default function Hero() {
    return (
      <div className="page-hero">
      <img
        className="page-hero" 
        src={HeroImg} 
       alt="Introducing Studio Guillotine"
      />

      </div>
      
    );
  }