import { motion } from 'framer-motion';

const animations = {
    initial: {opacity: 0, y: -100},
    animate: {opacity: 1, y: 0 },
    exit: {opacity: 0, y: -100},
};

const AnimatedPage = ({ children }) => {
    return (
        <motion.div
            key={ children }
            variants={animations} 
            initial="initial" 
            animate="animate"
            exit="exit"
            transition={{ duration: .3 }}
            className="page-content" 
        >
            { children }
        </motion.div>
    )
}

export default AnimatedPage;