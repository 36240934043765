import AnimatedPage from "../components/AnimatedPage/AnimatedPage";

export function Contact() {
    
    return (
        <AnimatedPage>
        <div className="contact-content">
            <h1>Drop us a message.</h1>
            <iframe
            title="Form Name"
            src="https://form.jotform.com/230497532234051"
            style={{
                width: '100%',
                height: '896px',
                scrolling: 'no',
      
            }}
            />
            <div className="iframeframe"></div>
        </div>
        </AnimatedPage>
    );
}