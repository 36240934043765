import AnimatedPage from "../components/AnimatedPage/AnimatedPage.js";

export function Privacy() {

    return (
        <AnimatedPage>


<div className="contact-content">

            <h1>Privacy Policy</h1>
            <p> Welcome to Studio Guillotine! This Privacy Policy explains how we collect, use, and protect your personal information when you visit our website located at guillotine.nz (the "Website"). By accessing or using our Website, you consent to the practices described in this Privacy Policy.</p>

            <h2>Information We Collect:</h2>
            
            <h3>1.1 Personal Information:</h3>
            <p>We may collect personal information that you provide to us voluntarily through our contact forms, such as your name, email address, phone number, and any other information you choose to provide.</p>

            <h3>1.2 Usage Data:</h3>
            <p>We may also collect non-personal information about your interaction with our Website. This includes your IP address, browser type, operating system, referring URLs, and pages visited. We use Google Analytics to gather this information and improve our Website's performance and user experience. Please refer to Google Analytics' terms of service and privacy policy for more information on their data collection practices.</p>

            <h2>Use of Information:</h2>

            <h3>2.1 Personal Information:</h3>
            <p>We may use the personal information you provide to respond to your inquiries, provide customer support, and fulfill your requests. Additionally, we may use your personal information to communicate with you about our services, updates, promotions, and other relevant information. We will never sell, rent, or lease your personal information to any third parties.</p>

            <h3>2.2 Usage Data:</h3>
            <p>We collect non-personal information to analyze trends, track user engagement, and improve the overall quality of our Website. This information helps us understand how visitors interact with our Website, which pages are most popular, and how we can enhance our services.</p>

            <h3>Cookies:</h3>
            <p>We use cookies on our Website to enhance your browsing experience. Cookies are small text files stored on your device that help us remember your preferences, analyze website traffic, and tailor content to your interests. You can adjust your browser settings to refuse cookies or notify you when cookies are being sent. However, please note that certain features of our Website may not function properly without cookies.</p>

            <h3>Third-Party Services:</h3>
            <p>We may use third-party services, such as JotForm, to collect information from you. When you submit information through these services, the data you provide is governed by their respective privacy policies. We encourage you to review the privacy policies of these third-party services before submitting any personal information.</p>

            <h3>Data Security:</h3>
            <p>We implement appropriate technical and organizational measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, please be aware that no method of transmission over the internet or electronic storage is 100% secure. While we strive to protect your personal information, we cannot guarantee its absolute security.</p>

            <h3>Children Policy:</h3>
            <p>Our Website is not intended for children under the age of 16. We do not knowingly collect or solicit personal information from children under the age of 16. If you believe that we have collected personal information from a child under 16, please contact us immediately, and we will take appropriate steps to remove the information from our records.</p>

            <h3>Changes to the Privacy Policy:</h3>
            <p>We reserve the right to modify or update this Privacy Policy at any time. If we make any material changes, we will notify you by posting the revised policy on our Website or through other means. Your continued use of the Website following the changes will signify your acceptance of the revised Privacy Policy.</p>

            <h3>Contact Us:</h3>
            <p>If you have any questions, concerns, or requests regarding this Privacy Policy or our data practices, please write to us at:</p>
            <p>Studio Guillotine <br/>
            2/149A Mokoia Road<br/>
            Birkenhead 0626, Auckland, NZ
            </p>

            <p>By using our Website, you acknowledge that you have read and understood this Privacy Policy and agree to its terms and conditions.</p>

            </div>
            
        </AnimatedPage>
      
    );

}