import React, { useState } from 'react';

import { Routes, Route,  useLocation } from 'react-router-dom';

import './scss/styles.css';
import Footer from './components/footer/footer.js';
import Header from './components/header/header.js';
import Social from './components/social/social';
import Button from './components/button/button';

import { Home } from './pages/Home';
import { Contact } from './pages/Contact';
import { Privacy } from './pages/Privacy';
import { AnimatePresence } from 'framer-motion';

function App() {
  const [isContactFormVisible, setIsContactFormVisible] = useState(false);
  const location = useLocation();

  const handleContactButtonClick = () => {
    setIsContactFormVisible(true);
  };

  const handleBackButtonClick = () => {
    setIsContactFormVisible(false);
  };


  return (
    <div className="App">
      
      <Header />
    
      <AnimatePresence mode={ "wait" }>
      
        <Routes>
          <Route path="/" element={<Home />} key="home" />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy" element={<Privacy />} />
          
        </Routes>

        </AnimatePresence>
        
        <div className="splash-content">
          <p>A <span className="accent-text">(r)</span>evolutionary digital design and marketing studio leveraging cutting edge artificial intelligence and old-fashioned human creativity.</p>
          <Social />
              {/* Conditional rendering of button text */}
              {location.pathname !== '/contact' && !isContactFormVisible && (
        <Button onClick={handleContactButtonClick} text="Contact Us"  to="/contact"  />
          )}
          {location.pathname === '/contact' && (
            <Button onClick={handleBackButtonClick} text="Back" to="/" />
          )}

        </div>
  
      <Footer />
      
    </div>
  );
}

export default App;