import Hero from "../components/hero/hero";
import AnimatedPage from "../components/AnimatedPage/AnimatedPage.js";

export function Home() {

    return (
        <AnimatedPage>
            
            <Hero />
        
        </AnimatedPage>
      
    );

}